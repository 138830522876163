



























































import { mdiEye, mdiEyeOff } from '@mdi/js';
import Vue from 'vue';
import { required } from 'vuelidate/lib/validators';
import PageTitle from '@/components/specific/PageTitle.vue';
import { HINT_PASSWORD_COMPLEX, MESSAGE_CHANGE_DONE } from '@/resources/defines';
import ServiceFactory from '@/services/ui/ServiceFactory';
import { UICommonMapper } from '@/store/modules/ui/common';

const AuthService = ServiceFactory.get('auth');

export default Vue.extend({
  name: 'PasswordPage',

  components: {
    PageTitle,
  },

  props: {
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },

  data(): {
    hint: string;
    icons: {
      [key: string]: string;
    };
    loading: boolean;
    showedDialog: {
      confirm: boolean;
    };
    showedPassword: {
      new: boolean;
      old: boolean;
    };
    updateParams: {
      previous_password: string;
      proposed_password: string;
    };
  } {
    return {
      hint: HINT_PASSWORD_COMPLEX,
      icons: {
        mdiEye,
        mdiEyeOff,
      },
      loading: false,
      showedDialog: {
        confirm: false,
      },
      showedPassword: {
        new: false,
        old: false,
      },
      updateParams: {
        // APIによりパラメータ名が定義されているため除外
        /* eslint-disable @typescript-eslint/camelcase */
        previous_password: '',
        proposed_password: '',
        /* eslint-enable @typescript-eslint/camelcase */
      },
    };
  },

  methods: {
    ...UICommonMapper.mapActions(['setMessage', 'setErrorMessage']),
    showConfirm() {
      const self = this;

      self.$v.$touch();

      if (self.$v.$invalid) {
        self.$$log.debug(self.$v);
        return;
      }

      self.showedDialog.confirm = true;
    },

    async updatePassword() {
      const self = this;

      self.loading = true;

      try {
        await AuthService.updatePassword(self.updateParams);

        self.setMessage({
          color: 'success',
          text: MESSAGE_CHANGE_DONE,
        });

        self.loading = false;
        self.showedDialog.confirm = false;

        await AuthService.tokenRefresh();
      } catch (error) {
        self.loading = false;
        self.showedDialog.confirm = false;
        self.$$log.error(error);
        self.setErrorMessage({ text: error.message });
      }
    },
  },

  validations() {
    return {
      updateParams: {
        // APIによりパラメータ名が定義されているため除外
        /* eslint-disable-next-line @typescript-eslint/camelcase */
        previous_password: {
          // エラーメッセージを表示したい順序で定義する
          /* eslint-disable vue/sort-keys */
          required,
          passwordComplexity: this.$$validators.passwordComplexity,
          /* eslint-enable vue/sort-keys */
        },
        // APIによりパラメータ名が定義されているため除外
        /* eslint-disable-next-line @typescript-eslint/camelcase */
        proposed_password: {
          // エラーメッセージを表示したい順序で定義する
          /* eslint-disable vue/sort-keys */
          required,
          passwordComplexity: this.$$validators.passwordComplexity,
          /* eslint-enable vue/sort-keys */
        },
      },
    };
  },
});
